var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-container",attrs:{"id":"contact","data-aos":"fade-up","data-aos-offset":"80","data-aos-delay":"40","data-aos-duration":"500","data-aos-mirror":"true"}},[_c('h1',[_vm._v("Contact")]),_c('form',{attrs:{"action":"https://formspree.io/f/meqpggww","method":"post"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],class:{
        'is-invalid-input': _vm.$v.name.$model && _vm.$v.name.$error,
        valid: _vm.$v.name.$dirty && !_vm.$v.name.$error
      },attrs:{"type":"text","name":"name","placeholder":"Name"},domProps:{"value":(_vm.name)},on:{"blur":function($event){return _vm.$v.name.$touch()},"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],class:{
        'is-invalid-input': _vm.$v.email.$model && _vm.$v.email.$error,
        valid: _vm.$v.email.$dirty && !_vm.$v.email.$error
      },attrs:{"type":"email","name":"email","placeholder":"Email"},domProps:{"value":(_vm.email)},on:{"blur":function($event){return _vm.$v.email.$touch()},"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],class:{
        'is-invalid-button': _vm.$v.message.$model && _vm.$v.message.$error,
        valid: _vm.$v.message.$dirty && !_vm.$v.message.$error
      },attrs:{"name":"message","id":"message","cols":"40","rows":"10","placeholder":"Message"},domProps:{"value":(_vm.message)},on:{"blur":function($event){return _vm.$v.message.$touch()},"input":function($event){if($event.target.composing){ return; }_vm.message=$event.target.value}}}),_c('button',{class:{
        'is-invalid-button': _vm.$v.$invalid
      },attrs:{"type":"submit","disabled":_vm.$v.$invalid}},[_vm._v(" SEND ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }